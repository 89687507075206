var http = require('http');

var url = require('url');

var https = module.exports;

for (var key in http) {
  if (http.hasOwnProperty(key)) https[key] = http[key];
}

https.request = function (params, cb) {
  params = validateParams(params);
  return http.request.call(this, params, cb);
};

https.get = function (params, cb) {
  params = validateParams(params);
  return http.get.call(this, params, cb);
};

function validateParams(params) {
  if (typeof params === 'string') {
    params = url.parse(params);
  }

  if (!params.protocol) {
    params.protocol = 'https:';
  }

  if (params.protocol !== 'https:') {
    throw new Error('Protocol "' + params.protocol + '" not supported. Expected "https:"');
  }

  return params;
}

void function register() {
  /* react-hot-loader/webpack */
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal["default"] : undefined;

  if (!reactHotLoader) {
    return;
  }
  /* eslint-disable camelcase, no-undef */


  var webpackExports = typeof __webpack_exports__ !== 'undefined' ? __webpack_exports__ : exports;
  /* eslint-enable camelcase, no-undef */

  if (!webpackExports) {
    return;
  }

  if (typeof webpackExports === 'function') {
    reactHotLoader.register(webpackExports, 'module.exports', "/usr/local/lib/node_modules/react-static/node_modules/https-browserify/index.js");
    return;
  }
  /* eslint-disable no-restricted-syntax */


  for (var key in webpackExports) {
    /* eslint-enable no-restricted-syntax */
    if (!Object.prototype.hasOwnProperty.call(webpackExports, key)) {
      continue;
    }

    var namedExport = void 0;

    try {
      namedExport = webpackExports[key];
    } catch (err) {
      continue;
    }

    reactHotLoader.register(namedExport, key, "/usr/local/lib/node_modules/react-static/node_modules/https-browserify/index.js");
  }
}();